import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import kebabCase from 'kebab-case'
import Layout from '../layouts/layout'
import TitleBar from '../components/title-bar'
import SEO from '../components/seo'

const Tags = ({ pageContext, data, location }) => {
  const { tag } = pageContext
  const { group, edges, totalCount } = data.allMarkdownRemark

  return (
    <Layout location={location}>
      <SEO title={'Tags'} description={"Daseveny's tags."} />
      <div
        className="flex flex-wrap flex-col justify-start items-start w-full md:w-full-v h-full shadow-md pt-3 md:pt-10 px-2 md:px-10 pb-1 md:pb-4 mb-1 md:mb-2"
        style={{
          color: 'var(--textNormal)',
          backgroundColor: 'var(--bg-card)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out',
          fontSize: '1rem',
          lineHeight: 1.6
        }}
      >
        <TitleBar to={location.pathname} title="ផ្លាក" location={location} />

        <div className="flex justify-center self-center items-center pt-2 md:pt-4 pb-4 md:pb-10">
          <svg
            className={`fill-current py-6 h-20-v md:h-30-v mt-10 md:mb-0`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 811 304"
          >
            <defs />
            <path
              fill="#e6e6e6"
              d="M23.272 268.343a17.584 17.584 0 0016.98-2.991c5.947-4.992 7.812-13.214 9.329-20.828L54.069 222l-9.396 6.47c-6.756 4.651-13.665 9.453-18.343 16.191s-6.719 15.938-2.961 23.23"
              data-name="Path 438"
            />
            <path
              fill="#f2f2f2"
              d="M24.722 297.18c-1.182-8.615-2.399-17.342-1.567-26.05.737-7.733 3.096-15.285 7.9-21.48a35.733 35.733 0 019.167-8.307c.917-.578 1.76.874.848 1.45a33.97 33.97 0 00-13.438 16.213c-2.925 7.44-3.395 15.551-2.891 23.456.305 4.78.952 9.528 1.602 14.27a.87.87 0 01-.587 1.034.845.845 0 01-1.033-.587z"
              data-name="Path 439"
            />
            <path
              fill="#e6e6e6"
              d="M33.234 283.356a12.944 12.944 0 0011.279 5.823c5.71-.27 10.47-4.255 14.754-8.039l12.673-11.19-8.387-.4c-6.032-.29-12.22-.56-17.965 1.302s-11.043 6.337-12.094 12.283"
              data-name="Path 442"
            />
            <path
              fill="#f2f2f2"
              d="M21.38 302.133c5.693-10.074 12.296-21.269 24.095-24.847a26.888 26.888 0 0110.134-1.047c1.077.093.808 1.752-.266 1.66a24.98 24.98 0 00-16.173 4.278 42.038 42.038 0 00-11.117 11.996c-1.84 2.803-3.49 5.726-5.138 8.644-.527.933-2.068.259-1.535-.684z"
              data-name="Path 443"
            />
            <path
              fill="#e6e6e6"
              d="M544.027 255.176a24.215 24.215 0 0023.382-4.119c8.19-6.874 10.758-18.196 12.847-28.682l6.18-31.016-12.938 8.908c-9.304 6.406-18.818 13.019-25.26 22.298s-9.252 21.947-4.078 31.988"
              data-name="Path 438"
            />
            <path
              fill="#f2f2f2"
              d="M546.024 294.886c-1.628-11.863-3.303-23.88-2.158-35.871 1.014-10.65 4.263-21.05 10.878-29.58a49.206 49.206 0 0112.625-11.44c1.262-.797 2.424 1.203 1.167 1.997a46.78 46.78 0 00-18.504 22.326c-4.03 10.246-4.676 21.415-3.982 32.3.42 6.582 1.31 13.121 2.206 19.652a1.198 1.198 0 01-.808 1.423 1.163 1.163 0 01-1.423-.808z"
              data-name="Path 439"
            />
            <path
              fill="#e6e6e6"
              d="M557.746 275.85a17.825 17.825 0 0015.53 8.019c7.865-.374 14.419-5.86 20.318-11.07l17.452-15.41-11.55-.552c-8.306-.398-16.827-.771-24.738 1.793s-15.208 8.726-16.654 16.915"
              data-name="Path 442"
            />
            <path
              fill="#f2f2f2"
              d="M541.422 301.706c7.84-13.871 16.932-29.288 33.18-34.215a37.026 37.026 0 0113.956-1.442c1.482.128 1.112 2.412-.367 2.285a34.398 34.398 0 00-22.272 5.892c-6.28 4.275-11.17 10.218-15.308 16.52-2.535 3.86-4.805 7.884-7.076 11.902-.726 1.285-2.847.357-2.113-.942z"
              data-name="Path 443"
            />
            <path
              fill="#cbcbcb"
              d="M470.55 46.456a3.675 3.675 0 01-2.048-4.44 1.766 1.766 0 00.08-.408 1.843 1.843 0 00-3.31-1.221 1.766 1.766 0 00-.205.361 3.675 3.675 0 01-4.44 2.048 1.765 1.765 0 00-.408-.08 1.843 1.843 0 00-1.222 3.31 1.766 1.766 0 00.362.204 3.675 3.675 0 012.048 4.441 1.766 1.766 0 00-.08.408 1.843 1.843 0 003.31 1.221 1.766 1.766 0 00.204-.362 3.675 3.675 0 014.441-2.047 1.767 1.767 0 00.408.08 1.843 1.843 0 001.22-3.31 1.767 1.767 0 00-.36-.205z"
              style={{ isolation: 'isolate' }}
            />
            <path
              fill="#f1f1f1"
              d="M540.55 209.456a3.675 3.675 0 01-2.048-4.44 1.766 1.766 0 00.08-.408 1.843 1.843 0 00-3.31-1.221 1.766 1.766 0 00-.205.361 3.675 3.675 0 01-4.44 2.048 1.765 1.765 0 00-.408-.08 1.843 1.843 0 00-1.222 3.31 1.766 1.766 0 00.362.204 3.675 3.675 0 012.048 4.441 1.766 1.766 0 00-.08.408 1.843 1.843 0 003.31 1.221 1.766 1.766 0 00.204-.362 3.675 3.675 0 014.441-2.047 1.767 1.767 0 00.408.08 1.843 1.843 0 001.22-3.31 1.767 1.767 0 00-.36-.205zM173.55 114.456a3.675 3.675 0 01-2.048-4.44 1.766 1.766 0 00.08-.408 1.843 1.843 0 00-3.31-1.221 1.766 1.766 0 00-.205.361 3.675 3.675 0 01-4.44 2.048 1.765 1.765 0 00-.408-.08 1.843 1.843 0 00-1.222 3.31 1.766 1.766 0 00.362.204 3.675 3.675 0 012.048 4.441 1.766 1.766 0 00-.08.408 1.843 1.843 0 003.31 1.221 1.766 1.766 0 00.204-.362 3.675 3.675 0 014.441-2.047 1.767 1.767 0 00.408.08 1.843 1.843 0 001.22-3.31 1.767 1.767 0 00-.36-.205z"
              style={{ isolation: 'isolate' }}
            />
            <circle
              cx="326.654"
              cy="50"
              r="6"
              fill="#f1f1f1"
              style={{ isolation: 'isolate' }}
            />
            <circle cx="646.654" cy="34" r="6" fill="#f87171" />
            <circle cx="180.654" cy="6" r="6" fill="#cbcbcb" />
            <circle cx="157.58" cy="241.623" r="43.067" fill="#2f2e41" />
            <path
              fill="#2f2e41"
              d="M137.954 275.423h13.084v23.442h-13.084zM164.122 275.423h13.084v23.442h-13.084z"
            />
            <ellipse
              cx="148.857"
              cy="299.137"
              fill="#2f2e41"
              rx="10.903"
              ry="4.089"
            />
            <ellipse
              cx="175.025"
              cy="298.592"
              fill="#2f2e41"
              rx="10.903"
              ry="4.089"
            />
            <ellipse
              cx="393.184"
              cy="515.502"
              fill="#2f2e41"
              rx="23.892"
              ry="7.501"
              transform="rotate(-45.022 -63.588 601.156)"
            />
            <ellipse
              cx="300.478"
              cy="518.502"
              fill="#2f2e41"
              rx="7.501"
              ry="23.892"
              transform="rotate(-44.978 -156.686 604.413)"
            />
            <circle cx="155.768" cy="235.52" r="14.719" fill="#fff" />
            <circle cx="155.768" cy="235.52" r="4.906" fill="#3f3d56" />
            <path
              fill="#fff"
              d="M167.452 262.743a9.572 9.572 0 01-18.835 3.429l-.003-.019c-.942-5.202 3.08-7.043 8.282-7.985s9.615-.627 10.556 4.575z"
            />
            <path
              fill="#e5e5e5"
              d="M199.81 286.823a3.66 3.66 0 00-3.656 3.657v7.637a3.66 3.66 0 003.656 3.656h58.83a3.66 3.66 0 003.656-3.656v-7.637a3.66 3.66 0 00-3.656-3.657z"
            />
            <path
              fill="#f87171"
              d="M233.642 281.737a1.586 1.586 0 00-2.166.58l-2.186 3.787-2.143-4.234a1.586 1.586 0 10-2.829 1.432l1.667 3.295h-.61V302h8.154v-15.403h-.862l1.555-2.694a1.586 1.586 0 00-.58-2.166zM156.47 213.375a76.085 76.085 0 01-22.564-3.447 2.529 2.529 0 01-1.752-2.398V190a2.503 2.503 0 012.5-2.5h44a2.503 2.503 0 012.5 2.5v17.52a2.506 2.506 0 01-1.779 2.4 79.185 79.185 0 01-22.904 3.455z"
            />
            <path
              d="M180.654 190v2.93l-23.81 8.35a2.016 2.016 0 01-1.37-.02l-22.82-8.57V190a2.006 2.006 0 012-2h44a2.006 2.006 0 012 2z"
              opacity=".2"
            />
            <path
              fill="#f87171"
              d="M156.177 197.892a2.502 2.502 0 01-.878-.16l-34.101-12.804a2.5 2.5 0 01.07-4.706l32.085-10.976a2.525 2.525 0 011.6-.007l35.81 11.937a2.5 2.5 0 01.036 4.73l-33.795 11.845a2.497 2.497 0 01-.827.14z"
            />
            <path fill="#3f3d56" d="M125.154 197h-1v-14.5h30.5v1h-29.5V197z" />
            <circle cx="124.654" cy="198" r="2" fill="#3f3d56" />
            <circle cx="406.58" cy="241.623" r="43.067" fill="#2f2e41" />
            <path
              fill="#2f2e41"
              d="M386.954 275.423h13.084v23.442h-13.084zM413.122 275.423h13.084v23.442h-13.084z"
            />
            <ellipse
              cx="397.857"
              cy="299.137"
              fill="#2f2e41"
              rx="10.903"
              ry="4.089"
            />
            <ellipse
              cx="424.025"
              cy="298.592"
              fill="#2f2e41"
              rx="10.903"
              ry="4.089"
            />
            <ellipse
              cx="642.184"
              cy="515.502"
              fill="#2f2e41"
              rx="23.892"
              ry="7.501"
              transform="rotate(-45.022 185.412 601.156)"
            />
            <ellipse
              cx="556.478"
              cy="556.502"
              fill="#2f2e41"
              rx="23.892"
              ry="7.501"
              transform="rotate(-55.225 174.37 593.425)"
            />
            <circle cx="404.768" cy="235.52" r="14.719" fill="#fff" />
            <circle cx="409.768" cy="230.52" r="4.906" fill="#3f3d56" />
            <path
              fill="#fff"
              d="M422.415 262.743c1.444 5.201-3.815 10.185-11.747 11.132s-15.533-2.502-16.977-7.703l-.005-.019c-1.436-5.202 4.698-7.043 12.63-7.985s14.663-.627 16.1 4.575z"
            />
            <path
              fill="#f87171"
              d="M391.51 216.276a76.085 76.085 0 01-22.221 5.22 2.529 2.529 0 01-2.52-1.57l-6.54-16.265a2.503 2.503 0 011.387-3.252l40.823-16.415a2.503 2.503 0 013.252 1.387l6.537 16.255a2.506 2.506 0 01-.756 2.89 79.185 79.185 0 01-19.961 11.75z"
            />
            <path
              d="M405.227 185.567l1.094 2.719-18.976 16.63a2.016 2.016 0 01-1.28.492l-24.369.562-1.003-2.496a2.006 2.006 0 011.11-2.601l40.823-16.415a2.006 2.006 0 012.601 1.11z"
              opacity=".2"
            />
            <path
              fill="#f87171"
              d="M385.462 202.02a2.502 2.502 0 01-.874.181l-36.416.842a2.5 2.5 0 01-1.691-4.393l25.674-22.154a2.525 2.525 0 011.481-.603l37.678-2.284a2.5 2.5 0 011.799 4.376l-26.936 23.597a2.497 2.497 0 01-.715.438z"
            />
            <path
              fill="#3f3d56"
              d="M356.346 212.767l-.928.373-5.409-13.453 28.298-11.379.373.928-27.371 11.006 5.037 12.525z"
            />
            <circle cx="356.255" cy="213.881" r="2" fill="#3f3d56" />
            <path
              fill="#f87171"
              d="M570.537 139.869a76.085 76.085 0 01-17.333-14.853 2.529 2.529 0 01-.22-2.961l9.265-14.882a2.503 2.503 0 013.444-.8l37.352 23.255a2.503 2.503 0 01.8 3.444l-9.26 14.873a2.506 2.506 0 01-2.778 1.096 79.185 79.185 0 01-21.27-9.172z"
            />
            <path
              d="M603.421 132.807l-1.549 2.488-24.625-5.496a2.016 2.016 0 01-1.153-.742l-14.842-19.336 1.422-2.284a2.006 2.006 0 012.755-.64l37.351 23.256a2.006 2.006 0 01.641 2.754z"
              opacity=".2"
            />
            <path
              fill="#f87171"
              d="M578.472 126.57a2.503 2.503 0 01-.662-.6l-22.18-28.893a2.5 2.5 0 012.546-3.958l33.039 7.64a2.525 2.525 0 011.361.84l24.09 29.06a2.5 2.5 0 01-2.47 4.036l-34.948-7.807a2.497 2.497 0 01-.776-.318z"
            />
            <path
              fill="#3f3d56"
              d="M552.607 109.416l-.849-.529 7.664-12.309 25.892 16.121-.529.848-25.043-15.592-7.135 11.461z"
            />
            <circle cx="551.654" cy="110" r="2" fill="#3f3d56" />
            <circle cx="674.728" cy="192.623" r="43.067" fill="#2f2e41" />
            <path
              fill="#2f2e41"
              d="M693.42 221.777l11.165-6.823 12.224 20.002-11.164 6.823zM671.093 235.423l11.164-6.823 12.224 20.002-11.164 6.823z"
            />
            <ellipse
              cx="902.147"
              cy="538.875"
              fill="#2f2e41"
              rx="10.903"
              ry="4.089"
              transform="rotate(-31.431 275.365 735.492)"
            />
            <ellipse
              cx="879.535"
              cy="552.055"
              fill="#2f2e41"
              rx="10.903"
              ry="4.089"
              transform="rotate(-31.431 252.753 748.673)"
            />
            <ellipse
              cx="828.124"
              cy="466.502"
              fill="#2f2e41"
              rx="7.501"
              ry="23.892"
              transform="rotate(-44.978 370.96 552.413)"
            />
            <ellipse
              cx="913.829"
              cy="507.502"
              fill="#2f2e41"
              rx="7.501"
              ry="23.892"
              transform="rotate(-34.775 340.753 669.067)"
            />
            <circle cx="661.54" cy="180.52" r="14.719" fill="#fff" />
            <circle cx="656.54" cy="175.52" r="4.906" fill="#3f3d56" />
            <path
              fill="#fff"
              d="M642.585 205.073c-2.79 4.573.928 10.76 8.307 13.818s15.622 1.831 18.413-2.742l.01-.016c2.784-4.576-2.623-8-10.004-11.054s-13.942-4.582-16.726-.006z"
            />
            <path
              fill="#e6e6e6"
              d="M660.277 138.547c3.845-15.487 20.82-24.6 37.915-20.356s27.834 20.24 23.988 35.727-16.604 15.537-33.698 11.293-32.05-11.177-28.205-26.664z"
            />
            <path fill="#cbcbcb" d="M810 304H1a1 1 0 010-2h809a1 1 0 010 2z" />
          </svg>
        </div>

        <div className="flex flex-col w-full mx-2 md:mx-0 mb-4">
          <div className="flex mt-4 mb-4">
            <span className="mr-2">{`អត្ថបទ​ដែលមានផ្លាក៖`}</span>
            <span className="text-red-400 mr-1">"{tag}"</span>
            <div className="text-blue-400">(</div>
            <span className="text-red-400">{totalCount}</span>
            <div className="text-blue-400">)</div>
          </div>

          {edges.map(({ node }) => {
            const { slug } = node.fields
            const { title } = node.frontmatter
            return (
              <div className="ml-2 md:ml-4" key={slug}>
                <span
                  className="mr-1"
                  role="img"
                  aria-label="Pointing emoji"
                  aria-labelledby="Pointing emoji"
                >
                  👉
                </span>
                <Link
                  className="shadow-none hover:text-red-400 underline"
                  to={slug}
                >
                  {title}
                </Link>
              </div>
            )
          })}
        </div>

        <div className="mt-4 mb-4 mx-2 md:mx-0">ផ្លាកផ្សេងទៀត៖</div>
        <div className="flex flex-wrap mx-2 md:mx-0">
          {group.map((tag) => (
            <div className="mr-2 mb-2 px-4 py-2 border" key={tag.fieldValue}>
              <Link
                className="flex shadow-none hover:text-red-400"
                to={`/tags/${kebabCase(tag.fieldValue)}/`}
              >
                <div className="mr-1">{tag.fieldValue}</div>
                <div className="text-blue-400">(</div>
                <div className="text-red-400">{tag.totalCount}</div>
                <div className="text-blue-400">)</div>
              </Link>
            </div>
          ))}
        </div>

        <Link
          className="flex self-center shadow-none underline mt-4 mb-10 text-red-400 hover:text-red-300"
          to="/tags"
        >
          ផ្លាកទាំងអស់
        </Link>
      </div>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired
        }).isRequired
      ),
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired
            })
          })
        }).isRequired
      )
    })
  })
}

export default Tags

export const pageQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, published: { eq: true } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
